.post-container {
  display: flex;
  flex-direction: column;
  width: 60%;
  border-radius: 20px;
  padding: 2% 4% 2% 4%;
}
.post-container:hover {
  background-color: rgba(248, 231, 237, 1);
  cursor: pointer;
}
.post-container:active {
  color: white;
}
.post-container .title {
  font-size: 40px;
  margin-bottom: 2%;
}
.post-container .read-time-container {
  display: flex;
  align-items: center;
  color: grey;
}
.post-container .read-time-icon {
  margin-right: 3px;
}

.post-container .body {
  margin-top: 1%;
  color: black;
}

.long-and-truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 960px) {
  .post-container {
    width: 90%;
    padding: 20px 0 20px 0;
  }
  .post-container .title {
    font-size: 20px;
  }
}
