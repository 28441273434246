.blog-detail-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  padding: 2% 4% 2% 4%;
}
.blog-detail-container .title {
  font-size: 40px;
  margin-bottom: 2%;
}
.blog-detail-container .read-time-container {
  display: flex;
  align-items: center;
  color: grey;
}
.blog-detail-container .read-time-icon {
  margin-right: 3px;
}

.blog-detail-container .body {
  margin-top: 3%;
  color: black;
}

.blog-detail-container a {
  text-decoration-line: underline;
  color: blue;
}

.blog-detail-container .signature {
  font-size: 40px;
  font-family: "Brush Script MT", "Lucida Handwriting", "Papyrus";
}

@media screen and (max-width: 960px) {
  .blog-detail-container .title {
    font-size: 25px;
  }
  .blog-detail-container .body {
    width: 100%;
  }
  .blog-detail-container a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
