.instruction-introduction-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.instruction-introduction-container .title {
  font-size: 2.5rem;
}

.instruction-introduction-container .subtitle {
  font-size: 2rem;
}

.instruction-introduction-container .body {
  text-align: center;
  font-size: 1.5rem;
  margin-right: 15px;
  margin-left: 15px;
}

.instruction-introduction-container .btn-container {
  display: flex;
}
.instruction-introduction-container .btn-container .btn {
  color: pink;
  border-color: pink;
  height: 4rem;
  width: 20rem;
  margin: 20px;
}
.instruction-introduction-container .btn-container .btn:hover {
  color: white;
  background-color: pink;
}

@media screen and (max-width: 960px) {
  .instruction-introduction-container .btn-container {
    flex-direction: column;
  }
  .instruction-introduction-container .btn-container .btn {
    margin: 10px;
  }
}
