.shopper-designer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.shopper-designer-container h1 {
  font-size: 3.75em;
  font-weight: 500;
  text-align: center;
  width: 80%;
  margin: 1%;
}

.shopper-designer-container p {
  font-size: 1.5em;
  width: 90%;
  text-align: center;

}

.shopper-designer-container .log-in-btn {
  color: rgb(191, 177, 196);
  border-color: rgb(191, 177, 196);
  border-radius: 10px;
  font-size: 19px;
  height: 60px;
  background-color: white;
  width: 200px;
}
.shopper-designer-container .log-in-btn:hover {
  color: white;
  background-color: rgb(191, 177, 196);
  border-color: white;
}

@media screen and (max-width: 960px) {
  .shopper-designer-container {
    margin-bottom: 0;
  }
  .shopper-designer-container h1 {
    font-size: 30px;
    font-weight: 500;
    width: 90%;
  }
  .shopper-designer-container p {
    font-size: 20px;
    width: 95%;
    text-align: center;
  }
  .shopper-designer-container .log-in-btn {
    height: 40px;
    width: 150px;
    margin-bottom: 20px;
    margin-top: 10px;
  }
}
