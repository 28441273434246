body {
  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

/* button {
  border-radius: 10px;
  font-size: 19px;
  height: 75px;
  background-color: white;
  width: 200px;
}
button:hover {
  background-color: black;
  color: white;
  cursor: pointer;
} */

button {
  cursor: pointer;
}

/* input {
  border: 1px solid black;
  height: 60px;
  width: 18%;
  max-width: 250px;
  margin-right: 15px;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
} */

/* select {
  height: 60px;
  max-width: 300px;
  margin-right: 15px;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
} */

a {
  text-decoration: none;
  color: black;
}

/* .input-label {
  font-weight: 600;
  font-size: 20px;
} */
/* .input-container {
  display: flex;
  flex-direction: column;
} */
