.footer-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 30vh;
  width: 100%;
  margin-top: 50px;
}

.footer-icons {
  margin-bottom: 50px;
}
.footer-icons a {
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 50px;
}

.footer-legal {
  display: flex;
  width: 500px;
  justify-content: space-between;
  align-self: flex-start;
  font-size: 15px;
  margin-left: 30px;
}
.footer-legal span {
  color: grey;
}
.footer-legal {
  margin-bottom: 20px;
}
.footer-legal .documents {
  width: 220px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 960px) {
  .footer-icons {
    margin-bottom: 10px;
  }
  .footer-legal {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 0;
    margin-bottom: 20px;
  }
  .footer-legal .bar {
    display: none;
  }
  .footer-legal .documents {
    width: 250px;
    margin-top: 10px;
  }
}
