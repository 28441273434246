.checkbox-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
}

.checkbox-container .checkbox-label-container {
  display: flex;
}
.checkbox-container .checkbox-label-container .label {
  font-weight: 600;
  font-size: 1.2rem;
}
.checkbox-container .checkbox-label-container .label-icon {
  margin-left: 5px;
}

.checkbox-container .checkbox-options-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 50%);
  grid-auto-rows: 45px;
  margin-top: 5px;
}
.checkbox-container .checkbox-options-container .checkbox-option {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
}
.checkbox-container .checkbox-options-container .checkbox-option .other-input {
  margin-left: 10px;
}

/* @media screen and (max-width: 960px) {
  .checkbox-container .checkbox-options-container {
    grid-template-columns: repeat(2, 50%);
  }
} */
