.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgot-password-container .form {
  height: 100%;
  padding: 0 50px 0 50px;
  display: flex;
  flex-direction: column;
  background-color: rgba(191, 177, 196, 0.1);
  width: 300px;
  border-radius: 20px;
}
.forgot-password-container .form .welcome-message {
  font-family: Monaco, Verdana, serif;
  margin: 50px 0 50px 0;
}
.forgot-password-container .form a {
  color: rgb(191, 177, 196);
}

.forgot-password-container .login-input {
  margin: 10px 0 10px 0;
}

.forgot-password-container .form .log-in-btn {
  color: pink;
  border-color: pink;
  height: 50px;
  align-self: center;
}
.forgot-password-container .form .log-in-btn:hover {
  color: white;
  background-color: pink;
}

@media screen and (max-width: 960px) {
  .forgot-password-container .form {
    background-color: white;
    padding: 0;
    width: 90%;
  }
  .forgot-password-container .form .welcome-message {
    margin: 0;
    font-size: 1.5rem;
    align-self: center;
  }
  .forgot-password-container .login-logo-container {
    display: none;
  }
  .forgot-password-container .form .login-logo.mobile {
    display: block;
    height: 150px;
    margin-bottom: -50px;
  }
}
