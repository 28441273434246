#designer-qa-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#designer-qa-container p {
    text-align: left;
    line-height: 1.5em;
}
#email-link {
    text-decoration: solid black underline;
    font-weight: 500;
}
#email-link:hover {
    color: rgb(191, 177, 196);
    text-decoration: solid rgb(191, 177, 196) underline;
}