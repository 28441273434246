.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
#page-indicator-container {
  display: flex;
  align-self: center;
  width: 50px;
  justify-content: space-between;
  color: pink;
}
.signup-container .form {
  height: 100%;
  padding: 0 50px 0 50px;
  display: flex;
  flex-direction: column;
  background-color: rgba(191, 177, 196, 0.1);
  border-radius: 0 20px 20px 0;
  width: 300px;
}
.signup-container .form .welcome-message {
  font-family: Monaco, Verdana, serif;
  margin: 50px 0 20px 0;
}
.signup-container .signup-logo-container {
  background-color: rgb(191, 177, 196);
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 20px 0 0 20px;
}
.signup-container .signup-logo-container .signup-logo {
  height: 70%;
  padding: 50px;
}
.signup-container .form .signup-input {
  margin-bottom: 10px;
}
.signup-container .form a {
  color: rgb(191, 177, 196);
}
.signup-container .form .signup-btn {
  color: pink;
  border-color: #ffc0cb;
  height: 50px;
  align-self: center;
  width: 80%;
}
.signup-container .form .signup-btn:hover {
  color: white;
  background-color: pink;
}
.signup-container .form .signup-btn.previous {
  margin-bottom: 10px;
}

.checkbox-container {
  max-width: 300px;
  /* border: 1px solid black; */
  display: flex;
}
.checkbox-container .checkbox {
  height: 20px;
}
.checkbox-container .input {
  margin-left: 10px;
  height: 20px;
}

@media screen and (max-width: 960px) {
  .signup-container {
    flex-direction: column;
  }
  .signup-container .form {
    background-color: white;
    padding: 0;
    width: 90%;
  }
  .signup-container .form .welcome-message {
    margin: 0;
    font-size: 1.5rem;
    align-self: center;
  }
  #page-indicator-container {
    margin-top: 20px;
  }
  .signup-container .signup-logo-container {
    display: none;
  }
  .signup-container .form .signup-logo.mobile {
    display: block;
    height: 150px;
    margin-bottom: -50px;
  }
}
