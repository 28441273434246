.measurement-detail-container {
  margin: 0 5% 0 5%;
}

.measurement-detail-container .measurement {
  padding: 0 0 0 1%;
  font-size: 20px;
}

.measurement-detail-container .body-part-key {
  display: inline-block;
  min-width: 200px;
}
.measurement-detail-container .body-part-value {
  display: inline-block;
  min-width: 50px;
  text-align: end;
}

.measurement-detail-container .switch-container {
  display: flex;
  align-items: center;
  margin-left: 50px;
}
.measurement-detail-container .switch-container span {
  padding: 0 10px 0 10px;
}
