#upload-file-container {
    background-color: rgb(255, 245, 247);
    border: 2px dashed rgb(255, 189, 200);
    padding: 1em;
    height: 45vh;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#file-input {
    position: relative;
    margin-bottom: 1.5em;
}

#default-input {
    position: relative;
    max-width: 200px;
    height: 46px;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
}

#upload-btn {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    background-color: rgb(255, 189, 200);
    font-size: 1.1em;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    outline: none;
    transition: background-color 0.4s;
}

#file-upload-icon {
    margin-right: 5px;
}
#file-delete-icon {
 margin-left: 15px;
}

#uploaded-file-container {
    display: flex;
    align-items: center;
    background-color: rgb(255, 189, 200, 0.3);
    padding: 15px 20px;
    border-radius: 10px;
}

@media screen and (max-width: 960px) {
    #upload-file-container {
        width: 80%;
        height: auto;
        margin-top: 10px;
    }
}