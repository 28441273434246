.measurement-container {
  display: flex;
  flex-direction: column;
}
.measurement-container .title {
  font-size: 2rem;
  margin-left: 30px;
}

.measurement-container .inner-container-without-title {
  display: flex;
}

.measurement-container .inner-container-without-title .media-left-container {
  display: flex;
  flex-direction: column;
  flex: 3;
  align-items: center;
}
.measurement-container
  .inner-container-without-title
  .media-left-container
  .video {
  width: 90%;
  height: 450px;
}
.measurement-container
  .inner-container-without-title
  .media-left-container
  .image {
  height: 450px;
}
.measurement-container
  .inner-container-without-title
  .media-left-container
  .video-wrapper
  .video {
  width: 90%;
  height: 450px;
}

.switch-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.switch-label {
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.measurement-container .inner-container-without-title .text-right-container {
  display: flex;
  flex-direction: column;
  flex: 4;
  align-items: center;
}
.measurement-container
  .inner-container-without-title
  .text-right-container
  .description {
  margin: 60px 20px 0 20px;
  flex: 1;
  font-size: 1.5rem;
  align-self: flex-start;
}
.measurement-container
  .inner-container-without-title
  .text-right-container
  .btn-container
  .btn {
  width: 200px;
  height: 50px;
  border-radius: 50px;
  color: pink;
  border-color: pink;
  margin: 30px 20px 0 20px;
}
.measurement-container
  .inner-container-without-title
  .text-right-container
  .btn-container
  .btn:hover {
  color: white;
  background-color: pink;
}

@media screen and (max-width: 960px) {
  .measurement-container .inner-container-without-title {
    flex-direction: column;
  }
  .measurement-container
    .inner-container-without-title
    .text-right-container
    .btn-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .measurement-container
    .inner-container-without-title
    .text-right-container
    .btn-container
    .btn {
    width: 90%;
  }
  .switch-container {
    margin-top: 10px;
  }
  .measurement-container
    .inner-container-without-title
    .media-left-container
    .video {
    width: 90%;
    height: 300px;
  }

  .measurement-container
    .inner-container-without-title
    .text-right-container
    .input {
    margin-top: 20px;
  }
}
