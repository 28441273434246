.contact-container {
  display: flex;
  margin-top: 100px;
}

.contact-right {
  display: flex;
  flex-direction: column;
  margin: 5% 5% 0 5%;
}

.contact-left {
  display: flex;
  justify-content: center;
}
img {
  border-radius: 10px;
}
.contact-left img {
  width: 85%;
}
.contact-right a {
  color: black;
}

@media screen and (max-width: 960px) {
  .contact-container {
    margin-top: 20px;
    flex-direction: column;
  }
}
