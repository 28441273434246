.blog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.blog-container .title {
  font-size: 60px;
  font-weight: 500;
}

.blog-container .spinner-container {
  display: flex;
  justify-content: center;
  margin: 50px 0 0 0;
}

@media screen and (max-width: 960px) {
  .blog-container .title {
    font-size: 30px;
  }
}
