.press-component-container {
  display: flex;
  margin-bottom: 100px;
  margin-top: 100px;
}
.press-component-container .image-container {
  flex: 1;
  display: flex;
  justify-content: center;
}
.press-component-container .title-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5%;
}
.press-component-container .image {
  max-width: 60%;
}
.press-component-container .component-title {
  font-size: 40px;
  font-weight: 500;
  text-align: center;
}

.press-component-container a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 960px) {
  .press-component-container {
    flex-direction: column;
    margin-bottom: 0px;
    margin-top: 50px;
  }
  .press-component-container .image {
    max-width: 90%;
  }
  .press-component-container .component-title {
    font-size: 20px;
    font-weight: 500;
    margin: 5px 5% 5px 5%;
  }
}
