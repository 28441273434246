form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#designer-email-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
}
#more-info {
    font-size: 1em;
    font-weight: 500;
    text-align: center;
}
#designer-email-textfield {
    width: 100%;
}
#designer-email-btn {
    margin-top: .5em;
    color: rgb(191, 177, 196);
    border-color: rgb(191, 177, 196);
    border-radius: 10px;
    background-color: white;
}
#designer-email-btn:hover {
    color: white;
    background-color: rgb(191, 177, 196);
    border-color: white;
}