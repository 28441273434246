.newsletter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(191, 177, 196);
}
.newsletter-container h1 {
  margin-top: 50px;
  color: white;
  font-weight: 500;
  width: 90%;
  text-align: center;
}
.newsletter-container p {
  color: white;
  margin-top: -5px;
  width: 90%;
  text-align: center;
}

.newsletter-container .input-container {
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 50px;
  justify-content: center;
}
.newsletter-container .input-inner-container {
  display: flex;
  width: 70vw;
  align-items: center;
}
.newsletter-container .input-container .success-message {
  color: white;
  width: 100%;
  text-align: center;
}
.newsletter-container #newsletter-input {
  background-color: white;
  border: 0;
  width: 20vw;
  height: 60px;
  margin-right: 15px;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.newsletter-container input::placeholder {
  color: #d4d4d4;
}
.input-container .input-inner-container Button {
  background-color: transparent;
  color: white;
  border: 3px solid white;
  border-radius: 10px;
  font-size: 19px;
  margin: 0;
  height: 50px;
}
.input-container .input-inner-container Button:hover {
  background-color: white;
  color: #bfb1c4;
  cursor: pointer;
}

.newsletter-error-message-container {
  display: flex;
  justify-content: center;
}
.newsletter-error-message-container .newsletter-error-message {
  color: red;
  background-color: pink;
  border-radius: 10px;
  text-align: center;
  padding: 5px 20px 5px 20px;
  margin-bottom: -5px;
}

@media screen and (max-width: 960px) {
  .newsletter-container h1 {
    margin-top: 20px;
    width: 90%;
    text-align: center;
    font-size: 1.5rem;
  }
  .newsletter-container p {
    color: white;
    margin-top: -10px;
    width: 90%;
    text-align: center;
    font-size: 1rem;
  }
  .newsletter-container .input-container {
    margin-bottom: 20px;
  }
  .newsletter-container .input-inner-container {
    flex-direction: column;
    width: 100%;
  }
  .input-container .input-inner-container #newsletter-input {
    width: 80%;
    margin: 5px 0 5px 0;
    font-size: 1rem;
    height: 2.5rem;
  }
  .input-container .input-inner-container button {
    margin-top: 10px;
  }
}
