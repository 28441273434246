.my-measurement-container {
  display: flex;
  flex-direction: column;
}
.my-measurement-container h2 {
  margin-left: 100px;
  display: flex;
  align-items: center;
}
.my-measurement-container h2:hover {
  cursor: pointer;
}
.my-measurement-container .information-container {
  display: flex;
  flex-direction: column;
  border: 1px solid grey;
  border-radius: 10px;
  width: 40%;
  margin: 5px 100px 5px 100px;
  padding: 20px;
  gap: 20px;
}
.my-measurement-container .information-container .demographics-btn {
  color: pink;
  border-color: #ffc0cb;
}
.my-measurement-container .information-container .demographics-btn:hover {
  background-color: rgba(255, 192, 203, 0.2);
}
.my-measurement-container .information-container .demographics-btn.update {
  color: white;
  background-color: rgba(255, 192, 203, 0.8);
}
.my-measurement-container
  .information-container
  .demographics-btn.update:hover {
  background-color: rgb(255, 192, 203);
}

.no-measurement-text {
  margin: 20px;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .my-measurement-container h2 {
    margin-left: 5px;
  }
  .my-measurement-container .information-container {
    width: 80%;
    align-self: center;
  }
  .my-measurement-container .edit-icon {
    margin: 5px 10px 0px 0px;
  }
}
