.fit-tip-container {
  display: flex;
  flex-direction: row;
  padding: 5% 5% 0 5%;
  justify-content: space-between;
}

.fit-tip-image {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.fit-tip-image img {
  width: 90%;
}
.fit-tip-image p {
  font-weight: bold;
  font-size: 25px;
  padding: 5% 10% 0 10%;
}

.fit-tip-list {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fit-tip-list.without-image {
  width: 100%;
}
.fit-tip-list p {
  font-weight: bold;
  font-size: 25px;
}
.fit-tip-list span {
  font-weight: normal;
  font-size: 17px;
}
.fit-tip-list ol {
  font-size: 17px;
  margin-left: 15%;
}

@media screen and (max-width: 960px) {
  .fit-tip-container {
    flex-direction: column;
  }
  .fit-tip-image {
    width: 100%;
  }
  .fit-tip-list {
    width: 100%;
  }
  .fit-tip-list ol {
    margin-left: 0;
    align-self: center;
  }
}
