.shopper-landing-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shopper-landing-page-container .link {
  display: flex;
  justify-content: center;
  width: 50%;
  border: 1px solid black;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dropdown-button {
  z-index: 100;
}

.dropdown {
  display: none;
  position: absolute;
  margin-top: 69px;
  background-color: #f9f9f9;
  width: 51%;
  z-index: 1;
}
.dropdown a {
  font-size: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.shopper-landing-page-container .dropdown a:hover,
.shopper-landing-page-container .dropdown a:active {
  background-color: #000;
  color: #fff;
}
.dropdown-button:hover .dropdown {
  display: flex;
  flex-direction: column;
}

.spinner-container {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 50vh;
}

.tapemeasure-link {
  text-decoration: underline;
}

@media screen and (max-width: 960px) {
  .shopper-landing-page-container .link {
    width: 80%;
  }
  .shopper-landing-page-container .welcome-message {
    width: 90%;
    text-align: center;
  }
  .shopper-landing-page-container .welcome-message .name.long {
    font-size: 1.5rem;
  }
  .dropdown {
    width: 81%;
  }
  .shopper-landing-page-container .tape-measure-statement {
    width: 90%;
  }
}
