.NavbarItems {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.navbar-logo {
  justify-content: start;
  cursor: pointer;
  margin: 45px 0 45px 50px;
}
.logo-image {
  height: 100px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links {
  color: #212121;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  color: grey;
  transition: all 0.5s ease-out;
}

.menu-icon {
  color: #212121;
  display: none;
  z-index: 999;
}
.menu-icon.close {
  position: fixed;
}

.nav-links-mobile {
  display: none;
}

.active {
  border-bottom: 1px solid black;
}

.logout-button {
  text-align: center;
  border: 1px solid black;
  border-radius: 5px;
  line-height: 40px;
  margin-right: 50px;
  width: 120px;
}
.logout-button:hover {
  background-color: pink;
  color: white;
  border: 1px solid transparent;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    justify-content: flex-start;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    margin: 0;
    padding: 0;
    top: 0;
    position: fixed;
    height: 100%;
    justify-content: flex-start;
  }

  .nav-menu.active {
    background: white;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 998;
    padding: 100px 0 30px 0;
  }

  .nav-links {
    text-align: center;
    padding: 15px 0 15px 0;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #212121;
  }

  .navbar-logo {
    margin: 40px 0 40px 20px;
  }
  .logo-image {
    height: 60px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 150%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    border-radius: 4px;
    width: 80%;
    background: rgb(248, 231, 237);
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
    margin-right: auto;
    margin-left: auto;
    padding: 10px 0 10px 0;
  }
  .nav-links-mobile.close {
    background-color: white;
    color: pink;
    font-weight: 500;
    font-size: 2rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #6568f4;
    transition: 250ms;
  }

  Button {
    display: none;
  }
  .active {
    border-bottom: none;
  }
  .logout-button {
    display: none;
  }
}
