.user-type-container {
  display: flex;
  margin: 5rem auto;
  height: 55vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.user-type {
  display: flex;
  flex: 1;
  justify-content: center;
  border: white solid 3px;
  width: 42%;
}
.user-type p {
  position: absolute;
  color: #707070;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 10px;
  padding: 5px;
  font-size: 2rem;
  align-self: center;
}
.cta-text {
  color: #707070;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  margin: auto;
  padding: 5px;
  font-size: 2.5rem;
  width: 50%;
  text-align: center;
}
#designer-img {
  background-image: url("../images/designer.jpg");
}
#shopper-img {
  background-image: url("../images/shopper.jpg");
}
#shopper-img, #designer-img {
  background-size: cover;
  height: 100%;
  max-width: 47%;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
}
.UN-logo-container {
  display: flex;
  justify-content: center;  
  margin-bottom: 7%;
}
.UN-logo {
  width: 30%;
}

@media screen and (max-width: 960px) {
  .user-type-container {
    flex-direction: column;
    margin: auto;
  }
  .user-type p {
    font-size: 1.25rem;
  }
  .user-type {
    width: 100%;
  }
  .cta-text {
    width: 100%;
    font-size: 1.25rem;
  }
  #shopper-img, #designer-img {
    background-size: cover;
    height: 100%;
    width: 100%;
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
  }
  .mission-statement {
    text-align: center;
    font-size: 25px;
    color: #707070;
    padding: 50px 10px 30px 10px;
  }
  .video-container {
    width: 90%;
    margin: 30px auto 0 auto;
    padding-top: 56.25%;
  }
  .video-container iframe {
    width: 100%;
    height: 90%;
  }
  .UN-logo-container .UN-logo {
    width: 50%;
  }
}
