#body-image-title{
    margin-left: 10px;
}
#body-photos-outer-container{
    display: flex;
    flex-direction: row;
}
#body-photos-left-container {
    display: flex;
    flex: 1;
    flex-direction: column;
}

#body-photos-right-container{
    display: flex;
    flex:1;
    justify-content: space-around;
    align-items: center;
}

#ex-body-image {
    width: 50%;
    height: auto;
    align-self: center;
}

#body-photos-description {
    margin: 0px 20px 10px 20px;
    flex: 1;
    font-size: 1.5rem;
    align-self: flex-start;
}

.measurement-container .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.measurement-container .btn-container .btn {
    width: 200px;
    height: 50px;
    border-radius: 50px;
    color: pink;
    border-color: pink;
    margin: 30px 20px 0 20px;
}
.measurement-container .btn-container .btn:hover {
  color: white;
  background-color: pink;
}

@media screen and (max-width: 960px) {
    #body-photos-outer-container{
        flex-direction: column;
    }
    #body-photos-right-container{
        flex-direction: column;
        margin-top: 20px;
    }
    #ex-body-image {
        width: 80%;
    }
    .measurement-container .btn-container {
        flex-direction: column;
    }
    .measurement-container .btn-container .btn {
        align-self: center;
        margin-top: 10px;
        width: 90%;
    }
}