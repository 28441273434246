.press-container {
  display: flex;
  flex-direction: column;
}
.press-container .title {
  font-size: 60px;
  font-weight: 500;
  align-self: center;
}
.press-container .spinner-container {
  display: flex;
  justify-content: center;
  margin-top: 50;
}

@media screen and (max-width: 960px) {
  .press-container .title {
    font-size: 30px;
  }
}
