#designer-cta-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
}
.cta-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#designer-cta-container > div > img {
    width: 7rem;
    height: auto;
}
#designer-cta-container > div > p {
    text-align: center;
    font-size: .75em;
    padding: 0 5%;
    font-weight: 500;
    max-width: 66%;
}

@media screen and (max-width: 960px) {
    #designer-cta-container {
        flex-direction: column;
    }
}