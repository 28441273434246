.fit-tips-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fit-tips-container .fit-tips-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 60px;
  font-weight: 500;
}

.fit-tips-container p {
  font-size: 20px;
  margin-top: 5px;
  width: 90%;
  text-align: center;
}
