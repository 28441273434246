#shopper-qa-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#shopper-qa-container p {
    text-align: left;
    line-height: 1.5em;
}
